/**
 * General
 */
import 'Partials/modal/modal.events';
/**
 * BJ Components
 */
import 'BobjollAccordionTabs';
import 'BobjollDropdown';
import 'BobjollPassword';
import 'BobjollPopover';
import 'BobjollScrollable';
import 'BobjollTags';
import 'BobjollUpload';
/**
 * Session
 */
import 'Auth/storage';
/**
 * FR Components
 */
import 'Partials/views';
import 'Partials/copy';
import 'Partials/filters-v2/initialize';
import 'Partials/detail';
import 'Partials/searchBar';
import 'Partials/home-anon';
import 'Partials/sponsor';
import 'Partials/tutorial';
/**
 * FR Pages
 */
import 'Project/ts/pages/all';
import 'Project/ts/pages/static';
import 'Project/ts/pages/user';
import 'Project/ts/pages/collection';
import 'Project/ts/pages/search';
import 'Project/ts/pages/download-limit';
/**
 * UI Components
 */
import 'Project/ts/ui/auth';
import 'Project/ts/ui/tag-slider';
/**
 * Collections
 */
import 'Collections';
/**
 * Events (microfunnel + ga4)
 */
import 'Partials/events/ga4.global';
import 'Library/shortcut';
import 'Library/animatePositionLogo';

import { q } from 'bobjoll/ts/library/dom';
/**
 * Currency
 */
import { Currency } from 'Library/currency';
import { checkElementOutOfView } from 'Library/helpers/element';
/**
 * Tracker
 */
import Scroll from 'Library/scroll';
/**
 * LandingPaid
 */
import { addScrollIntoLandingPaidView } from 'Partials/landing-paid/landing-paid';
/**
 * Notifications
 */
import { monthlyLimitMenuInfo, pauseSubscriptionNotifications, winbackNotifications } from 'Partials/notifications';

/**
 * Performance
 */
import { debugPerformance } from './debug/performance';
import { cookie } from './library/cookie';
/**
 * Dispath view
 */
import { setTestClasses } from './partials/test-ab';
import { refreshVerticalGrid } from './partials/showcase/showcase.vertical-grid';

Currency.updateDomPrices();

setTestClasses();

/**
 * Lazyload on scroll
 */
Scroll.add(() => Lazyload.run());

if (cookie.getItem('gr_j72d7fj5yk98dk20rcn_cucumber_debug')) {
    document.body.classList.add('cucumber-mode');
}

(async () => {
    import('Library/other-level');

    const { initializeHomeAnonPage } = await import('Project/ts/pages/home-anon');
    initializeHomeAnonPage(true);

    if (gr && gr.user) {
        winbackNotifications();
        pauseSubscriptionNotifications();
        const { deviceManager } = await import('Partials/user/device-manager');
        deviceManager.initialize();
        gr.checkUserLimitAndRenewal();
    }
})();

FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY && monthlyLimitMenuInfo();

(async () => {
    const { sendSalesforcePixel } = await import('Library/pixels/salesforce.pixel');
    sendSalesforcePixel();

    if (VIEW_TEMPLATE.match(/serie/)) {
        const { initializeSocial } = await import('Partials/social-share-advanced');
        initializeSocial();
    }
})();

/**
 *  Download page button action
 */
if (q('main.download')) {
    (async () => {
        await (await import('./partials/download/download.component')).initializeDownloadButton(
            'main.download .download-button',
        );
    })();
}

const filterRow = q('.filter-tags-row');
const sponsorElement = q('.main-spr:not(.spr-bottom)');

const size = document.body.dataset.hbsTemplate === 'author' ? -1 : -66;
const rootMargin = `${size}px 0px 0%`;

filterRow && checkElementOutOfView(filterRow, rootMargin);
sponsorElement && checkElementOutOfView(sponsorElement, rootMargin);

FEATURE_LANDINGS_PAID_BY_LANGUAGE && addScrollIntoLandingPaidView();

if (FEATURE_AD_MANAGER && q('.category-landing .section--content-category')) {
    (async () => {
        const { initContentBanner } = await import('./partials/adManager');
        initContentBanner(['LANDING_CATEGORY_BOTTOM']);
    })();
}

if (VIEW_TEMPLATE.match(/keyDates/)) {
    (async () => {
        const { initLandingCalendar } = await import('./partials/landing-calendar');
        initLandingCalendar();
    })();
}

if (VIEW_TEMPLATE.match(/categories|keyDates/)) {
    (async () => {
        await await import('BobjollTabs');
        const { initShowMore } = await import('Project/ts/library/helpers');
        initShowMore();
    })();
}

if (VIEW_TEMPLATE.match(/fontsView/)) {
    (async () => {
        const { initFontsView } = await import('./partials/fonts');
        initFontsView();
    })();
}

if (FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY) {
    (async () => {
        const { handleMenuHeaderLinks } = await import('Partials/events/ga4.author');
        handleMenuHeaderLinks();
    })();
}

if (FEATURE_DOWNLOAD_FROM_THUMBNAIL_BY_COUNTRY) {
    (async () => {
        const { UserHelpers } = await import('Partials/user');
        if (UserHelpers.isPremium()) {
            await (await import('Project/ts/partials/download/download.component')).initializeDownloadButton(
                '.showcase .showcase__item .download-button',
            );
        }
    })();
}

if (VIEW_TEMPLATE.match(/categories/)) {
    (async () => {
        const { handleMockupsBannerClick } = await import('Partials/events/ga4.mockups-banner');
        handleMockupsBannerClick();
    })();
    if (document.body.classList.contains('categories-landing--authentic')) {
        const showcases = Showcase.getElements(false, 'main');
        Showcase.setLayout({ showcases: showcases });
        window.addEventListener('resize', () => refreshVerticalGrid());
    }
}
if (VIEW_TEMPLATE.match(/search/)) {
    (async () => {
        const { handleTextToImageBannerClick } = await import('Partials/events/ga4.text-to-image-banner');
        handleTextToImageBannerClick();
    })();
}
if (VIEW_TEMPLATE.match(/keyword/)) {
    (async () => {
        const { handleBgGeneratorBannerClick } = await import('Partials/events/ga4.bg-generator-banner.ts');
        handleBgGeneratorBannerClick();
    })();
}
if (VIEW_TEMPLATE.match(/keyword/)) {
    (async () => {
        const { handleBgRemoverBannerClick } = await import('Partials/events/ga4.bg-remover-banner.ts');
        handleBgRemoverBannerClick();
    })();
}

debugPerformance();
